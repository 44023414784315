const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://subgrow.jp/',
    gaTrackingId: null,
  },
  lang: 'ja', // language list can be found in LangSwitcher.js
  header: {
    logo: 'https://subgrow.net/img/logo.svg',
    logoLink: 'https://subgrow.jp/',
    title: '',
    githubUrl: 'https://github.com/BackToTheSubscription/B2S',
    helpUrl: '',
    tweetText: '',
    // social: `<li>
    //     <a href="https://twitter.com/hasurahq" target="_blank" rel="noopener">
    //       <div class="twitterBtn">
    //         <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/twitter-brands-block.svg' alt={'Twitter'}/>
    //       </div>
    //     </a>
    //   </li>
    // 	<li>
    //     <a href="https://discordapp.com/invite/hasura" target="_blank" rel="noopener">
    //       <div class="discordBtn">
    //         <img src='https://graphql-engine-cdn.hasura.io/learn-hasura/assets/homepage/discord-brands-block.svg' alt={'Discord'}/>
    //       </div>
    //     </a>
    //   </li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/',
      '/registration',
      '/notifications',
      '/promotional_offers',
      '/metrics',
      '/demo_app',
    ],
    collapsedNav: [
    ],
    links: [
      // {text: 'Main site', link: 'https://backtosub.com'}
    ],
    frontline: false,
    title: 'Documentation',
  },
  siteMetadata: {
    title: 'SubGrow | Docs',
    description: 'Documentation for SubGrow.',
    ogImage: null,
    docsLocation: '',
    favicon: '/img/favicon.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'SubGrow | Docs',
      short_name: 'subgrow',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: '/img/favicon.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
